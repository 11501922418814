<template>
  <div
    :class="[
      'vms-project-files',
      isMobileScreen ? 'vms-project-files__mobile' : 'vms-content__container',
    ]"
  >
    <project-sidebar
      v-if="!isMediumScreen"
      sidebar-type="uploadedFiles"
    ></project-sidebar>
    <div
      class="vms-project-files__content vd-padding-left-20 vd-padding-top-20 vd-padding-right-20"
    >
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import ProjectSidebar from '@views/project/components/project-sidebar/project-sidebar'
import { mapGetters } from 'vuex'

export default {
  components: {
    ProjectSidebar,
  },

  computed: {
    ...mapGetters({
      isMobileScreen: 'common/isMobileScreen',
      isMediumScreen: 'common/isMediumScreen',
    }),
  },
}
</script>

<style lang="scss">
@import '@styles/views/project/uploaded-files';
</style>
